import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentHeader from "../ParentHeader/ParentHeader";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Loader from "../../Components/Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer";
import Help from "../../Components/Help/Help";
import CollaboratorsList from "../Components/CollaboratorsList"
import LocationDetails from "../Components/LocationDetails";
import AccommodationsList from "../Components/AccommodationsList";
import Description from "../Components/Description";
import DancersWithResult from "../Components/DancersWithResult";
import { formatDate } from "../../helper/formatDate";
import { useDispatch } from "react-redux";
import { setConversionRate } from "../Register-Events/helper";

const FeiseannazViewEvent = () => {
  const [loading, setLoading] = useState(true);
  const [dancerdetails, setDancerDetails] = useState([]);
  const [eventProp, setEventProp] = useState()
  const [urlEventName, setUrlEventName] = useState("")
  const [collaborators, setCollaborators] = useState()

  const navigate = useNavigate();
  const { state, search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const eventId = searchParams.get('id')
  const wrapperRef = useRef(null)
  const dispatch = useDispatch()

  //getting event details from passed values
  const event = state?.from;

  useEffect(() => {
    wrapperRef.current?.scrollTo({ top: -50, behavior: 'smooth' });
    getInitialDetails()
  }, []);

  //button handlers
  const backToViewEvent = () => {
    localStorage.getItem("accessToken") ? navigate("/parent") : navigate("/");
  };

  const goToRegisgternow = () => {
    const urlEventName = eventProp?.name?.split(" ").join("+");
    if (localStorage.getItem("accessToken")) {
      navigate(`/register-dancers?id=${eventId}`, {
        state: { event: eventProp },
      });
    } else {
      storeOnRegisterClick(urlEventName, eventProp);//in local for redirection
      navigate("/login", { replace: true });
    }
  };

  const competitorClickHandler = () => {
    const urlEventName = eventProp?.name?.split(" ").join("+");
    if (localStorage.getItem("accessToken")) {
      navigate(`/competitors/${urlEventName}?id=${eventProp?._id}`, {
        state: { event: eventProp, from: event },
      });
    } else {
      storeOnCompetitorClick(urlEventName, eventProp)
      navigate("/login");
    }
  };

  const viewSyllabus = (syllabusUrl) => {
    window.open(syllabusUrl, "_blank");
  };

  const goToViewResult = () => {
    const urlEventName = eventProp?.name?.split(" ").join("+");
    // if (localStorage.getItem("accessToken")) {
    navigate(`/results/${urlEventName}?id=${eventId}`, { state: { event: eventProp } });
    // } else {
    //   storeOnResultClick(urlEventName)
    //   navigate("/login");
    // }
  };

  //to get event,collaborator and dancer data
  const getInitialDetails = async () => {
    try {
      setLoading(true);
      const eventUrl = localStorage.getItem("accessToken") ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}` : `${process.env.REACT_APP_BASE_URL}/feis/single/${eventId}`
      const eventResponse = await serviceConsumer('GET', eventUrl)
      const eventNameForUrl = eventResponse?.Feis?.name?.split(" ").join("+");
      setConversionRate(dispatch,eventResponse?.Feis?.usdExchangeRate)
      setUrlEventName(eventNameForUrl)
      setEventProp(eventResponse.Feis)
      document.title = `Feis.Link - ${eventResponse?.Feis?.name}`;
      const collaboratorsUrl = `${process.env.REACT_APP_BASE_URL}/feis/collaborators/${eventId}`
      const collaboratorsResponse = await serviceConsumer('GET', collaboratorsUrl)
      setCollaborators(collaboratorsResponse.result)


      if (localStorage.getItem("accessToken")) {
        const dancerUrl = `${process.env.REACT_APP_BASE_URL}/feis/events/dancers/${eventId}`;
        const dancerResponse = await serviceConsumer("GET", dancerUrl);
        setDancerDetails(dancerResponse.result)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      navigate("pageNotFound", { replace: true })
    }
  };

  const storeOnRegisterClick = (urlEventName) => {
    localStorage.setItem("path", `/feis/${urlEventName}?id=${eventProp?._id}`);
    localStorage.setItem("eventId", eventProp?._id);
    localStorage.setItem("eventType", eventProp?.eventType);
    localStorage.setItem("from", "open");
  }

  const storeOnCompetitorClick = (urlEventName) => {
    localStorage.setItem("path", `/competitors/${urlEventName}?id=${eventProp?._id}`);
    localStorage.setItem("eventId", eventProp?._id);
    localStorage.setItem("eventType", eventProp?.eventType);
    localStorage.setItem("from", event);
    localStorage.setItem("isCompetitor", true);
  }

  const storeOnResultClick = (urlEventName) => {
    localStorage.setItem("path", `/feis/${urlEventName}?id=${eventProp?._id}`);
    localStorage.setItem("eventId", eventProp?._id);
    localStorage.setItem("from", "result");
    localStorage.setItem("eventType", eventProp?.eventType);
  }

  const printScheduleHandler = () => {
    window.open(eventProp.schedulePdfUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <div style={{ backgroundColor: "#FAFAFA" }} ref={wrapperRef}>
      <ParentHeader />
      {loading && <Loader />}
      {!loading && <div className="d-flex justify-content-center  view-header">
        <div className="d-flex  flex-grow-1 align-items-center">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center" style={{ color: "#68AD3F", cursor: "pointer", width: " min-content", }}>
              <div className="d-flex align-items-center" onClick={backToViewEvent}><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
              <div onClick={backToViewEvent} className="back">Back</div>
            </div>

            <div className="d-flex header-mobile">
              <div className="d-flex flex-grow-1 align-items-center">
                <img src={eventProp?.logo} className="event-img-view" alt="logo" />
                <div className="registerevent-details d-flex flex-grow-1 align-items-center">
                  <div>
                    <p className="title m-1"> Feisanna{event === "upcoming" && " - Upcoming"}{event === "closed" && " -Closed"}</p>
                    <p className="event-name m-1">{eventProp?.name}</p>
                    <p className="reg-date-open m-1">{moment(formatDate(eventProp?.feis_date)).format(" MMMM Do, YYYY")}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3 mb-md-0">
                <div className="d-flex align-items-center">
                  <div className="reg-details">
                    <p className="reg-date "> Registration{" "}{event === "closed" || event === "result" ? "closed:" : "closes:"}</p>
                    <p className="reg-date-close m-0">{moment(formatDate(eventProp?.closing_date)).format("MMMM Do, YYYY")}</p>
                  </div>
                </div>
                <div className=" d-flex align-items-center">
                  <div>
                    {event === "upcoming" && <button className="disabled">Register Now</button>}
                    {event === "open" && <button className="filled" onClick={goToRegisgternow}>Register Now</button>}
                    {event === "closed" && (<button className="disabled"> View Results</button>)}
                    {event === "result" && (<button className="filled" onClick={goToViewResult}>View Results</button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {!loading && <div className="view-body pb-5">
        <div className="parent-register row">
          <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 leftBlock">
            {event === "result" && dancerdetails.length > 0 && localStorage.getItem("accessToken") && <DancersWithResult dancerdetails={dancerdetails} />}
            <Description description={eventProp?.description} />
            <div className="mt-4 d-flex gap-2">
              {eventProp?.syllabus_file && <button className="outlined" onClick={() => viewSyllabus(eventProp?.syllabus_file)}>
                Syllabus
              </button>}
              {event !== "upcoming" && <button className="outlined" onClick={competitorClickHandler}>Competitors </button>}
              {eventProp?.schedulePdfUrl && <button className="outlined" onClick={printScheduleHandler}> Schedule </button>}
            </div>
            <CollaboratorsList collaborators={collaborators} event="feis" />
          </div>
          <br></br>
          <div className="col-lg-4 col-md-5 col-sm-12 col-xs-9">
            <LocationDetails event={eventProp} />
            <br></br>
            <AccommodationsList accomodations={eventProp?.accomodations} />
            <Help />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default FeiseannazViewEvent;
