import { sortCompetitions } from "./SortSyllabus";

// export const sortLevels = (levelData) => {
//   const customSortOrder = [
//     "BEGINNER",
//     "ADVANCED BEGINNER",
//     "NOVICE",
//     "PRIZEWINNER",
//     "PRELIMINARY CHAMPIONSHIPS",
//     "OPEN CHAMPIONSHIPS",
//     "TEAMS",
//     "ARTS AND CULTURE",
//     "Non-Level"
//   ];

//   const sortedKeys = Object.keys(levelData).sort((a, b) => {
//     const indexA = customSortOrder.indexOf(a);
//     const indexB = customSortOrder.indexOf(b);

//     // If a or b is not in customSortOrder, move it to the end
//     if (indexA === -1 && indexB === -1) {
//       return 0; // Keep the original order for items not in customSortOrder
//     } else if (indexA === -1) {
//       return 1; // Move items not in customSortOrder to the end
//     } else if (indexB === -1) {
//       return -1; // Move items not in customSortOrder to the end
//     }

//     return indexA - indexB;
//   });

//   const sortedLevels = {};
//   sortedKeys.forEach((key) => {
//     sortedLevels[key] = levelData[key];
//   });

//   return sortedLevels;
// };


export const sortLevels = (levelData) => {

  const customSortOrder = [
    "Non-Level",
    "First Feis",
    "Beginner",
    "Beginner 1",
    "Advanced Beginner",
    "Beginner 2",
    "Novice",
    "Prizewinner",
    "Preliminary Championships",
    "Open Championships",
    "Teams",
    "Adult",
    "Specials",
    "Arts and Culture",
  ].map(level => level.toUpperCase()); // Normalize sort order to uppercase

  const sortedKeys = Object.keys(levelData).sort((a, b) => {
    const normalizedA = a.toUpperCase(); // Normalize current key to uppercase
    const normalizedB = b.toUpperCase(); // Normalize other key to uppercase

    const indexA = customSortOrder.indexOf(normalizedA);
    const indexB = customSortOrder.indexOf(normalizedB);

    // If a or b is not in customSortOrder, move it to the end
    if (indexA === -1 && indexB === -1) {
      return 0; // Keep the original order for items not in customSortOrder
    } else if (indexA === -1) {
      return 1; // Move items not in customSortOrder to the end
    } else if (indexB === -1) {
      return -1; // Move items not in customSortOrder to the end
    }

    return indexA - indexB;
  });

  const sortedLevels = {};
  sortedKeys.forEach((key) => {
    sortedLevels[key] = levelData[key];
  });

  return sortedLevels;
};


export const filterCompBasedOnAgesAndTeams = (selectedLevelsOfIndex, dancer, levelData) => {
  //filter competitions for all levels based on dancer ages
  //for teams, in addition - filter with dancer levels if the levels are in teams 
  //filter competitions based on gender
  let filteredLevels;
  if (selectedLevelsOfIndex.toLowerCase() != "teams" && selectedLevelsOfIndex.toLowerCase() != "specials") {
    filteredLevels = levelData?.[selectedLevelsOfIndex]?.filter((item) => item?.ages?.some(age => dancer?.ages?.value?.includes(age) && (item?.gender === dancer?.gender || item?.gender === "Boys & Girls" || item?.gender === "Both")))
  } else {
    filteredLevels = levelData[selectedLevelsOfIndex]?.filter((item) => item?.ages?.some(age => dancer?.ages?.value?.includes(age) && (item?.gender === dancer?.gender || item?.gender === "Boys & Girls" || item?.gender === "Both")))
    filteredLevels = filteredLevels ? filteredLevels.filter((comp) => comp?.levels?.some(level => level?.toLowerCase() === dancer?.levels?.toLowerCase())) : []
  }
  const sortedCompInFilteredLevels = sortCompetitions(filteredLevels)
  return sortedCompInFilteredLevels;
}

export const setSelectedDataFromLevelData = (levelData, allDancer, selectedLevelData, setSelectedLevelData) => {
  const selectedLevelPriceData = {};

  Object.keys(levelData)?.forEach(level => {
    const item = levelData[level];
    allDancer[0]?.competitions?.map(competition => {
      const selected = item?.find(it => competition === it._id)
      if (selected) {
        const prev = selected ? selectedLevelPriceData[level]?.find(it => it._id === selected?._id) : null;
        if (!prev) {
          selectedLevelPriceData[level] = selectedLevelPriceData[level] ? [...selectedLevelPriceData[level], selected] : [selected]
        }
      }
    })
  })

  const data = {
    ...selectedLevelData[0],
    levelPriceData: selectedLevelPriceData
  };

  setSelectedLevelData([data]);
}