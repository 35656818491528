import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParentHeader from "../ParentHeader/ParentHeader";
import moment from "moment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Loader from "../../Components/Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer";
import Help from "../../Components/Help/Help";
import RegisteredDancers from "../Components/RegisteredDancers";
import CollaboratorsList from "../Components/CollaboratorsList";
import AccommodationsList from "../Components/AccommodationsList";
import LocationDetails from "../Components/LocationDetails";
import Description from "../Components/Description";
import { formatDate } from "../../helper/formatDate";
import { setConversionRate } from "../Register-Events/helper";
import { useDispatch } from "react-redux";

const ViewFiesEdit = () => {

  const [loading, setloading] = useState(false);
  const [event, setEvent] = useState()
  const [collaborators, setCollaborators] = useState([])
  const [dancers, setDancers] = useState([])

  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const eventId = searchParams.get('id')
  const dispatch = useDispatch()

  const getAllDetails = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/feis/registered/dancers/${eventId}`
      const response = await serviceConsumer("GET", url)

     setConversionRate(dispatch,response?.eventData?.usdExchangeRate)
      setEvent(response?.eventData)
      setDancers(response?.result)

      const collaboratorsUrl = `${process.env.REACT_APP_BASE_URL}/feis/collaborators/${eventId}`
      const collaboratorsResponse = await serviceConsumer('GET', collaboratorsUrl)
      setCollaborators(collaboratorsResponse.result)
      setloading(false)
    }
    catch (error) {
      navigate("pageNotFound", { replace: true })
      setloading(false)
      //
    }
  }
  useEffect(() => {
    setloading(true)
    window.scrollTo(0, 0);
    getAllDetails()
  }, [])

  useEffect(() => {
    document.title = `Feis.Link - ${event?.name}`
  }, [event])

  //button handlers
  const backToViewEvent = () => {
    navigate("/parent")
  };
  const competitorClickHandler = () => {
    const urlEventName = event?.name?.split(" ").join("+")
    navigate(`/competitors/${urlEventName}?id=${event?._id}`, {
      state: { from: "edit" },
    });
  };


  const viewSyllabus = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const printScheduleHandler = () => {
    window.open(event.schedulePdfUrl, '_blank', 'noopener,noreferrer');
  }
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <ParentHeader />
      {loading && <Loader />}
      {!loading && <>
        <div className="d-flex justify-content-center  view-header">
          <div className="d-flex  flex-grow-1 ">
            <div className="flex-grow-1">
              <div
                className="d-flex align-items-center"
                style={{ color: "#68AD3F", cursor: "pointer", width: " min-content", }}
              >
                <div onClick={backToViewEvent}><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
                <div
                  onClick={backToViewEvent}
                  style={{ marginLeft: "5px", fontWeight: "500", fontSize: "16px", marginTop: "2px", }}
                >
                  Back
                </div>
              </div>
              <div className="d-flex header-mobile">
                <div className="d-flex flex-grow-1 align-items-center">
                  <img src={event?.logo} className="event-img-view" alt="logo" />
                  <div className="registerevent-details-edit d-flex  align-items-center">
                    <div>
                      <p className="title m-1">{event?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"} - Entered</p>
                      <p className="event-name m-1">{event?.name}</p>
                      <p className="reg-date-open m-1">{moment(formatDate(event?.feis_date)).format(" MMMM Do, YYYY")}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                  </div>
                  <div className="registerbutton d-flex align-items-center gap-2">
                    <div className="p-1">
                      <button className="outlined" onClick={competitorClickHandler}>Competitors</button>
                    </div>
                    {event?.syllabus_file && <div>
                      <button className="filled" onClick={() => viewSyllabus(event?.syllabus_file)}  >Syllabus</button>
                    </div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-body">
          <div className="parent-register row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 leftBlock">
              <RegisteredDancers event={event} dancers={dancers} eventType="feis" />
              <Description description={event?.description} />
              <div>
                {event?.schedulePdfUrl && <button className="outlined" onClick={printScheduleHandler}> Schedule </button>}
              </div>
              <CollaboratorsList collaborators={collaborators} event="feis" />
            </div>
            <br></br>
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-9">
              <LocationDetails event={event} />
              <br></br>
              <AccommodationsList accomodations={event?.accomodations} />
            </div>
            <Help />
          </div>
        </div >
      </>}
    </div >
  );
};

export default ViewFiesEdit;
