import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { postPrintHandler } from '../../helper/printHandlers';
import { sortCompetitions } from '../../helper/SortSyllabus';
import { sortLevels } from '../../helper/RegFlowHelpers';
import { useSelector } from 'react-redux';
import { getConvertedCurrency } from '../Register-Events/helper';

const RegisteredDancers = ({ event, dancers, eventType }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState("")
    const currencyDetails = useSelector(state=>state.currencyReducer)


    const editRegistration = (dancerDetail) => {
        eventType === "feis" ? navigate(`/feis-edit-dancers/${event._id}?regId=${dancerDetail._id}`, { state: { event: event } }) :
            navigate(`/grade-edit-dancers/${event._id}?regId=${dancerDetail._id}`, { state: { event: event } })
    };

    const printCardHandler = async (e, dancer) => {
        e.stopPropagation();
        const urlApi = `${process.env.REACT_APP_BASE_URL}/registration/printcard/pdf`;
        const payload = { registrationId: dancer._id, type: 'one', };
        postPrintHandler(urlApi, payload, setLoading, setErr)
    };

    return (
        <>
            {loading && <Loader />}
            <p className="pageHeaders">Registered dancers</p>
            <hr className="mt-0" />
            {dancers.map((dancer) => {
                const sortedCompetitionsOfDancer = eventType === "feis" && sortCompetitions(dancer?.competitionDetails)
                const competitions = eventType === "feis" ? sortedCompetitionsOfDancer : dancer?.competitions;
                const result = competitions.reduce((acc, curr) => {
                    const key = curr.group.toLowerCase() === "teams" ? "TEAMS" : curr.group;

                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(`${curr.code}-${curr.description}-${getConvertedCurrency(currencyDetails,curr.price)}`);

                    const sortedData = eventType === "feis" ? sortLevels(acc) : acc;

                    return sortedData;
                }, {});


                return (
                    <div className="register-eventEdit" key={uuidv4()}>
                        <div className="d-flex justify-content-between">
                            <div className="col-6 col-sm-8">
                                <p className="eventGradename m-0">
                                    {dancer?.dancerData?.fname} {dancer?.dancerData?.lname}
                                    <br />
                                </p>
                                {eventType === "feis" && Object.entries(result).map(([group, codes]) => (
                                    <div key={group}>
                                        <div className="editGradeLvl">{group}</div>
                                        <div className="d-flex mb-1 flex-wrap">
                                            {codes.map((code) => (
                                                <div className="editGrades" style={{ marginRight: "5px" }} key={code}>{code}</div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                {eventType === "grade" && <>
                                    <div className="editGradeLvl">{dancer?.dancerData?.levels}</div>
                                    <div className="d-flex mb-1 flex-wrap">
                                        {dancer?.gradeDetails?.map((item, index) => {
                                            return <div key={index}>
                                                < div className="editGrades" style={{ marginRight: "5px" }} key={item.grade_name} > {item?.grade_name}</div>
                                            </div>
                                        })}
                                    </div>
                                </>}
                            </div>
                            {eventType === "feis" &&
                                <div>
                                    <div>
                                        {dancer?.dancerData?.status ? <div >
                                            <span className="web-btn-edit cursor-pointer" onClick={() => { editRegistration(dancer); }}>
                                                Edit registration<span className="editArrow">{`>`}</span>
                                            </span>
                                        </div> :
                                            <div className="delete-btn-dancer" style={{ color: "red" }}>
                                                Deleted dancer
                                            </div>}
                                    </div>
                                    <div className="web-btn-edit my-3 d-flex justify-content-end" onClick={(e) => printCardHandler(e, dancer)}>Print card</div>
                                </div>}
                            {eventType === "grade" && <div>
                                {dancer?.dancerData?.status ? <div >
                                    <span style={{ cursor: "pointer" }} className="web-btn-edit" onClick={() => { editRegistration(dancer); }}>
                                        Edit registration<span className="editArrow">{`>`}</span>
                                    </span>
                                </div> :
                                    <div className="delete-btn-dancer" style={{ color: "red" }}>
                                        Deleted dancer
                                    </div>}
                            </div>}
                        </div>

                    </div >
                )
            })}
        </>
    )
}

export default RegisteredDancers