
const INITIAL_STATE = {
    country:'',
     conversionRate:1,
   };
   
   export const currencyReducer = (state = INITIAL_STATE, action) => {
     switch (action.type) {
       case "SET_COUNTRY":
         return { ...state, ...action.payload };
       case "SET_CONVERSION_RATE":
         return { ...state, ...action.payload };
         default:
             return state;
     }
   };
   